<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="isLoading"
    rounded="md"
    variant="secondary"
  >
    <form-wizard
      ref="wizard"
      :start-index="startIndex"

      shape="circle"
    >
      <template slot="title">
        <div class="pt-1 d-flex flex-column align-items-center justify-content-start">
          <h2>{{ title }}</h2>
          <p>
            {{ description }}
          </p>
        </div>
      </template>

      <tab-content
        title="Basic details"
        :selected="true"
        :before-change="() => validateStep('step1')"
      >
        <div style="max-width: 560px" class="mx-auto">
          <validation-observer ref="step1">
            <b-form-group label="Name">
              <template v-slot:label> Name <span class="text-danger">*</span> </template>
              <validation-provider
                #default="{ errors }"
                vid="title"
                name="Name"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-input
                  v-model="formData.title"
                  id="title"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description">
              <template v-slot:label>
                Description <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                vid="description"
                name="Description"
                :rules="{
                  required: true,
                  min: 3,
                }"
              >
                <b-form-textarea
                  rows="3"
                  max-rows="6"
                  v-model="formData.description"
                  id="description"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Tags">
              <validation-provider vid="tags" #default="{ errors }" name="Tags">
                <v-select
                  v-model="formData.tags"
                  label="name"
                  placeholder="Search & Select"
                  multiple
                  taggable
                  :create-option="(text) => ({ name: text, isNew: true })"
                  :options="tagOptions"
                  @search="
                    (searchQuery) => {
                      getTagsBySearchPhraseAndSetOptions({
                        search: searchQuery,
                      });
                    }
                  "
                  :state="errors.length > 0 ? false : null"
                ></v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </div>
      </tab-content>
      <tab-content title="Questionnaires" :before-change="() => validateStep('step2')">
        <step-2
          :selectedQuestionnaires="formData.selectedQuestionnaires"
          :selectedAllQuestionnaires="formData.selected_all_questionnaires"
          @on-questionnaire-select="handleQuestionnaireSelected"
          @on-questionnaire-all-select="handleQuestionnaireAllSelected"
          @on-questionnaire-remove="handleQuestionnaireRemoveClick"
        />
      </tab-content>
      <tab-content title="Third Parties">
        <step-3
          :selectedThirdParties="formData.selectedThirdParties"
          :selectedAllThirdParties="formData.include_all_vendors"
          @on-thirdparty-select="handleThirdPartySelected"
          @on-thirdparty-all-select="handleThirdPartyAllSelected"
          @on-thirdparty-remove="handleThirdPartyRemoveClick"
        />
      </tab-content>

      <template slot="custom-buttons-right" slot-scope="props">
        <b-button
          @click="handleSaveToDraftClick"
          v-if="props.activeTabIndex != 2"
          variant="success"
          size="sm"
          class="mx-25"
        >
          <template v-if="isLoading">
            <b-spinner variant="light" small class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="SaveIcon" class="mr-50" />
          </template>
          <span class="align-middle">{{
            isLoading ? "Please Wait" : "Save & Exit"
          }}</span></b-button
        >
        <b-button size="sm" variant="success" 
        v-if="props.activeTabIndex == 2"
        @click="handleSaveToDraftClick"
        ><feather-icon icon="SaveIcon" class="mr-50" /><span class="align-middle"
          >Save As Draft</span
        ></b-button
      >
        <b-button
          class="mx-25"
          @click="handlePublishAndFinishClick"
          v-if="
            props.activeTabIndex == 2 &&
            (preloadData.status == 3 || Object.keys(preloadData).length === 0)
          "
          variant="success"
          size="sm"
          :disabled="
            (formData.selectedQuestionnaires.length == 0&&!formData.selected_all_questionnaires) ||
            (formData.selectedThirdParties.length == 0&&!formData.include_all_vendors)
          "
        >
          <template v-if="isLoading">
            <b-spinner variant="light" small class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="ArrowUpCircleIcon" class="mr-50" />
          </template>
          <span class="align-middle">{{
            isLoading ? "Please Wait" : "Finish & Publish"
          }}</span></b-button
        >
      </template>

      <b-button size="sm" variant="primary" slot="next"
        ><span class="align-middle">Continue</span
        ><feather-icon icon="ArrowRightIcon" class="ml-50"
      /></b-button>

      <b-button size="sm" variant="primary" slot="prev"
        ><feather-icon icon="ArrowLeftIcon" class="mr-50" /><span class="align-middle"
          >Back</span
        ></b-button
      >

      <b-button
          class="mx-25"
          @click="handlePublishAndFinishClick"
          v-if="
            props.activeTabIndex == 2 
            // &&
            // (preloadData.status == 3 || Object.keys(preloadData).length === 0)
          "
          variant="success"
          size="sm"
          :disabled="
            formData.selectedQuestionnaires.length == 0 ||
            formData.selectedThirdParties.length == 0
          "
          slot-scope="props"
          slot="finish"
        >
          <template v-if="isLoading">
            <b-spinner variant="light" small class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="ArrowUpCircleIcon" class="mr-50" />
          </template>
          <span class="align-middle">{{
            isLoading ? "Please Wait" : "Finish & Publish"
          }}</span></b-button
        >
    </form-wizard>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BFormTextarea,
  BBadge,
  VBHover,
  VBTooltip,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import { required, min } from "@validations";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import ResponseMixins from "../../../../mixins/ResponseMixins";
import TagMixins from "../../../../mixins/TagMixins";
import vSelect from "vue-select";
import ThirdPartyRisksRAMixins from "../../../../mixins/ThirdPartyRisksRAMixins";
import Step2 from "./steps/step2.vue";
import Step3 from "./steps/step3.vue";

export default {
  mixins: [ResponseMixins, TagMixins, ThirdPartyRisksRAMixins],
  components: {
    FormWizard,
    TabContent,
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BFormTextarea,
    BBadge,
    VBHover,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
    Step2,
    Step3,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    handleSubmitClick: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    preloadData: {
      type: Object,
      required: true,
      default: {},
    },
  },

  data() {
    return {
      // isLoading: false,
      formData: {
        title: null,
        description: null,
        tags: [],
        selectedQuestionnaires: [],
        selected_all_questionnaires:false,
        selectedThirdParties: [],
        include_all_vendors: false,
      },
      tagOptions: [],
      startIndex: 0,
      showOverlay: false,
    };
  },

  created() {
    this.load();
  },

  mounted() {
    if (Object.keys(this.preloadData).length > 0) {
      this.activateAllSteps();
    }
  },

  methods: {
    load() {
      const query = this.$route.query;
      if (query && query.startIndex) {
        console.log(query.startIndex);
        this.startIndex = Number(query.startIndex);
      }
      if (Object.keys(this.preloadData).length > 0) {
        console.log("preload Data", this.preloadData);
        this.fillFormData();
      }
    },

    activateAllSteps() {
      var wizard = this.$refs.wizard;
      wizard.activateAll();
    },

    fillFormData() {
      this.$set(this.formData, "title", this.preloadData.title);
      this.$set(this.formData, "description", this.preloadData.description);
      if (
        this.preloadData.questionnaireTemplates &&
        this.preloadData.questionnaireTemplates != null
      ) {
        this.$set(
          this.formData,
          "selectedQuestionnaires",
          this.preloadData.questionnaireTemplates
        );
      }
      if (this.preloadData.vendors && this.preloadData.vendors != null) {
        this.$set(this.formData, "selectedThirdParties", this.preloadData.vendors);
      }
    },

    handlePublishAndFinishClick() {
      let newFormData = { ...this.formData, publish: 1 };
      this.handleSubmitClick(newFormData);
    },

    handleSaveToDraftClick() {
      this.$refs["step1"].validate().then((success) => {
        if (success) {
          // this.createNewTPRAssessmentAndSetData(this.formData);
          this.handleSubmitClick(this.formData);
        }
      });
    },

    createNewTPRAssessmentAndSetData(data) {
      this.$emit("set-loading", true);

      // this.isLoading = true;
      this.createNewTPRAssessment(data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          // this.isLoading = false;
          this.$emit("set-loading", false);
        });
    },

    validateStep(name) {
      if (name == "step1") {
        var refToValidate = this.$refs[name];
        return refToValidate.validate();
      } else if (name == "step2") {
        if (this.formData.selectedQuestionnaires.length > 0||this.formData.selected_all_questionnaires) {
          return true;
        }
        this.showToast("Select atleast one questionnaire to continue.", "warning");
        return false;
      } else if (name == "step3") {
        if (this.formData.selectedThirdParties.length > 0||this.formData.selectedThirdParties) {
          return true;
        }
        this.showToast("Select atleast one third party to continue.", "warning");
        return false;
      }
    },

    handleThirdPartySelected(tprts) {
      this.formData.selectedThirdParties.push(tprts);
    },
    handleThirdPartyAllSelected() {
      this.formData.include_all_vendors=!this.formData.include_all_vendors
    },

    handleThirdPartyRemoveClick(id) {
      let newThirdParties = [...this.formData.selectedThirdParties];
      newThirdParties = newThirdParties.filter((tprty) => tprty._id != id);
      this.$set(this.formData, "selectedThirdParties", newThirdParties);
    },

    handleQuestionnaireSelected(questionnaire) {
      this.formData.selectedQuestionnaires.push(questionnaire);
    },
    handleQuestionnaireAllSelected() {
      this.formData.selected_all_questionnaires=!this.formData.selected_all_questionnaires;
    },

    handleQuestionnaireRemoveClick(id) {
      let newQuestionnaires = [...this.formData.selectedQuestionnaires];
      newQuestionnaires = newQuestionnaires.filter((qstr) => qstr._id != id);
      this.$set(this.formData, "selectedQuestionnaires", newQuestionnaires);
    },

    getTagsBySearchPhraseAndSetOptions(params) {
      this.getAssetTagsBySearchPhrase(params.search)
        .then((res) => {
          console.log(res);
          this.tagOptions = res.data.data.data.map((p) => {
            return { name: p.title, value: p._id };
          });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
