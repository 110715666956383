<template>
  <div class="w-100 h-auto">
    <div class="card shadow-none">
      <div class="card-header pt-0 pb-75 px-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-9"
          >
            <h4 class="mb-25">Select questionnaires</h4>
            <!-- <p class="mb-0">
              {{ description }}
            </p> -->
          </div>

          <div class="d-flex align-items-center justify-content-end col-sm-3">
            <!-- <b-badge
              v-if="goToSelectedAction"
              @click="goToSelectedAction"
              pill
              variant="warning"
              class="px-1 shadow-sm cursor-pointer"
              ><small class="align-middle">Total Selected:</small
              ><small class="ml-25"
                ><b-badge pill variant="dark">{{
                  selectedFeatures.length
                }}</b-badge></small
              ></b-badge
            >
            <b-badge v-else pill variant="warning" class="px-1 shadow-sm"
              ><small class="align-middle">Total Selected:</small
              ><small class="ml-25"
                ><b-badge pill variant="dark">{{
                  selectedFeatures.length
                }}</b-badge></small
              ></b-badge
            > -->
          </div>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end align-items-center" >
      <b-form-checkbox :checked="selectedAllQuestionnaires" variant="success" size="md" style="margin-top: -40px;" class="mr-2 custom-control-success" @change="handleAllSelectClick" switch >
        <p style="font-size: .9rem;">
          Select All
        </p>
      </b-form-checkbox>
    </div> -->
      <div class="w-100 d-flex flex-row align-items-end justify-content-between mb-75">
        <div class="app-fixed-search rounded border shadow-sm mb-0" style="width: 50%">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>


        <div style="width: 20%">
          <!-- <b-form-group class="mb-0">
            <label><small>Filter by Module</small></label>
            <b-form-select v-model="filters.module" :options="moduleOptions" />
          </b-form-group> -->
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div class="table-responsive mb-0" style="min-height: 50vh">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="questionnaires.length > 0">
                  <tr
                    role="row"
                    v-for="(qstnr, index) in questionnaires"
                    :key="qstnr._id"
                  >
                    <td scope="row">
                      {{ index + pagination.from }}
                    </td>

                    <td role="cell" style="width: 40vw">
                      <p class="mb-0">
                        {{ `${qstnr.title}` }} &nbsp;&nbsp; <b-badge v-if="qstnr.is_customized" variant="success"
                      >Customized</b-badge
                    >
                      </p>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="min-width: 8vw"
                      >
                      <b-button
                          style="min-width: 140px"
                          v-if="selectedAllQuestionnaires"
                          variant="danger"
                          disabled
                          size="md"
                          @click.stop="handleRemoveClick(qstnr._id)"
                          ><feather-icon icon="MinusIcon" class="mr-25" /><span
                            >Remove</span
                          ></b-button
                        >
                        <b-button
                          style="min-width: 140px"
                          v-else-if="!selectedQuestionnaires.some((q) => q._id == qstnr._id)"
                          variant="success"
                          size="md"
                          @click.stop="handleSelectClick(qstnr)"
                          ><feather-icon icon="PlusIcon" class="mr-25" /><span
                            >Select</span
                          ></b-button
                        >
                        <b-button
                          style="min-width: 140px"
                          v-else
                          variant="danger"
                          size="md"
                          @click.stop="handleRemoveClick(qstnr._id)"
                          ><feather-icon icon="MinusIcon" class="mr-25" /><span
                            >Remove</span
                          ></b-button
                        >
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Questionnaires Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems that there are no questionnaires at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BBadge
} from "bootstrap-vue";
import ResponseMixins from "../../../../../mixins/ResponseMixins";
import vSelect from "vue-select";
import EmptyTableSection from "../../../../../components/EmptyTableSection.vue";
import QuestionnaireMixins from "../../../../../mixins/QuestionnaireMixins";

export default {
  mixins: [ResponseMixins, QuestionnaireMixins],
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    EmptyTableSection,
    BPagination,
    BBadge,
    BFormCheckbox
  },

  props: {
    selectedQuestionnaires: {
      type: Array,
      required: true,
    },
    selectedAllQuestionnaires: {
      type: Boolean,
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: function (newValues) {
        this.getQuestionnaireAndSetData(newValues);
      },
    },
  },

  data() {
    return {
      filters: {
        search: null,
        page: 1,
      },
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        perPage: 10,
      },
      questionnaires: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getQuestionnaireAndSetData(this.filters);
    },

    handleRemoveClick(id) {
      this.$emit("on-questionnaire-remove", id);
    },

    handleSelectClick(qstnr) {
      this.$emit("on-questionnaire-select", qstnr);
    },
    handleAllSelectClick() {
      this.$emit("on-questionnaire-all-select");
    },

    getQuestionnaireAndSetData(params) {
      this.showOverlay = true;
      this.getImportedQuestionnaires(params)
        .then((res) => {
          console.log("Questionnaires", res);
          this.$set(this.pagination, "from", res.data.data.from);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "to", res.data.data.to);
          this.questionnaires = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
