<template>
  <assessment-form
    title="Create New Assessment"
    description="Complete the following steps and click Finish to create a new
            assessment."
    :handleSubmitClick="createNewTPRAssessmentAndSetData"
    :isLoading="isLoading"
    @set-loading="handleLoading"
  />
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import AssessmentForm from "./components/AssessmentForm.vue";
export default {
  mixins: [ThirdPartyRisksRAMixins, ResponseMixins],
  components: { AssessmentForm },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleLoading(value) {
      this.isLoading = value;
    },

    createNewTPRAssessmentAndSetData(data) {
      this.isLoading = true;
      this.createNewTPRAssessment(data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
